<template>
    <div>
        <common-table :data="list" :columns="columns" :loading.sync="loading" indexFixed="left" :pager="pager" @current-change="handleCurrentChange">
            <el-table-column slot="status" label="状态" align="center">
                    <template slot-scope="{row}">
                        <el-tag :type="row.status_desc==='已提交'?'success':'warning'">{{ row.status_desc }}</el-tag>
                    </template>
                </el-table-column>
            <el-table-column slot="operate" label="操作" align="center" fixed="right" width="150px">
                <template slot-scope="scope">
                    <!--  -->
                    <el-link @click="goDetail(scope.row)" type="primary">报价</el-link>
                    <el-link style="margin-left:10px" @click="submit(scope.row)" type="primary">提交</el-link>
                </template>
            </el-table-column>
        </common-table>
    </div>
</template>
<script >
import commonTable from '@/components/commonTable'
import { getPriceList, sumbitPrice } from "@/api";

export default {
    name: 'priceList',
    components:{
        commonTable
    },
    data(){
        return {
            loading: false,
            columns: [
                {label: '采购编号', prop: 'purchase_no', minWidth:"110px"},
                {label: '轮次', prop: 'quote_price_num'},
                {label: '采购描述', prop: 'purchase_subject'},
                {label: '采购员', prop: 'truename'},
                {label: '发布日期', prop: 'create_at', width:156},
                {label: '报价截止时间', prop: 'expire_time', width:156},
                {label: '状态', slot: 'status'},
                {label: '提交时间', prop: 'submit_time',width:156},
                {slot: 'operate'}
            ],
            pager: { 
                page_num:1, 
                page_size:20,    
                total: 0
            },
            list: []
        }
    },
    created(){
        this.getList();
    },
    methods: {
        getList(){
            let { total, ...params } = {...this.filter, ...this.pager};
            this.loading = true;
            getPriceList(params).then((res)=>{
                const { data } = res
                this.list = data.list;
                this.pager.total = parseInt(data.total);
            }).finally(()=>{
                this.loading = false
            })
        },
        handleCurrentChange(val){
            this.pager.page_num = val;
            this.getList();
        },

        //跳转报价详情
        goDetail(row){
            if(row.purchase_status===6){
                this.$alert('招标已作废', '提示', { type:'error'})
                return
            }
            if(new Date().getTime()  > new Date(row.expire_time).getTime()){
                this.$alert('报价时间已截止！', '提示', { type:'error'})
                return
            }
            this.$router.push({ name: 'PriceDtail', params: { id: row.id }})
        },
        submit(row){
            if(row.purchase_status===6){
                this.$alert('招标已作废', '提示', { type:'error'})
                return
            }
            if(new Date().getTime()  > new Date(row.expire_time).getTime()){
                this.$alert('报价时间已截止！', '提示', { type:'error'})
                return
            }


            this.$confirm('是否提交报价？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    this.loading = true
                    sumbitPrice({contract_object_id: row.id}).then((res)=>{
                        this.$alert(res.msg, '成功', { type:'success'})
                        this.getList();
                    }).finally(()=>{
                        this.loading = false
                    })
            }).catch(() => {    
            });

            
        }
    }
}
</script>
